<template>
<div>
  <template>
    <div id="breadcrumb-alignment" class="mb-1">
      <div class="d-flex justify-content-start breadcrumb-wrapper">
        <b-breadcrumb
          :items="breadCrumb()"
        />
      </div>
    </div>
  </template>

  <b-row class="match-height">
		<b-form @submit="formSubmit" class="mx-1 w-100">
			<b-card title="Edit Email User">
				<b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
					<div class="alert-body"> {{error_message}} </div>
				</b-alert>

				<b-row>
					<b-col md="12">
						<b-form-group label="Project Site" class="required">                
							<v-select
								v-model="form.sites"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								multiple
								label="site_name"
								:options="items"
								placeholder="Select"
								@input="getSiteUsers(); siteCrew(); getSiteGroup();"
							>
								<template #selected-option="{ site_name }">
									<span v-if="site_name.length < 23">{{site_name}}</span>
									<span v-if="site_name.length >= 23">{{site_name.substring(0, 23) + '...'}}</span>
								</template>
							</v-select>
						</b-form-group>
					</b-col>
				
					<b-col md="12">
						<b-form-group label="Search User">                
							<v-select                    
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								v-model="company_user"
								label="full_name"
								:options="company_users"
								placeholder="Type anything ..."
								@input="handleChange"
                :clearable="false"
							/>
						</b-form-group>
					</b-col>
              
					<b-col md="6">
						<b-form-group label="Person Name" class="required">
							<b-form-input placeholder="2 to 50 characters" v-model="form.name" autocomplete="off" disabled/>
						</b-form-group>
					</b-col>

					<b-col md="6">
						<b-form-group label="Email" class="required">
							<b-input-group class="input-group-merge">
								<b-input-group-prepend is-text>
									<feather-icon icon="MailIcon" />
								</b-input-group-prepend>
								<b-form-input type="text" placeholder="" v-model="form.email" autocomplete="off" disabled/>
							</b-input-group>
						</b-form-group>
					</b-col>

              	</b-row>

			</b-card>

			<b-card v-for="(rb,index) in form.report_breakdown" :key="index">
				<b-row class="mb-1">
          <b-col cols="11">
              <h4>Report Breakdown</h4>
          </b-col>
				</b-row>

				<b-row>
					<b-col md="6">
						<b-form-group label="Report" class="required">
							<v-select                    
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							v-model="rb.report"
							:options="report_list"
							placeholder="Select Report"
							@input="rb.email_custom_forms = null; rb.task_frequency = null; rb.task_group = ''; rb.task_crew = null"
							:clearable="false"
              />
						</b-form-group>
					</b-col>

					<b-col md="6">
						<b-form-group label="Report Format" class="required">
							<v-select                    
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								v-model="rb.report_format"
								:options="report_format_list"
								placeholder="Select Report Format"
                :clearable="false"
							/>
						</b-form-group>
					</b-col>

          <b-col md="12">
						<b-form-group label="Custom Form" class="required">
							<v-select
								v-model="rb.email_custom_forms"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:options="custom_form_list"
								multiple
								:getOptionLabel="option => option.form.title"
								placeholder="Select Custom Form"
								@input="checkforms($event, index)"
							>
							</v-select>
						</b-form-group>
					</b-col>
					
				</b-row>

        <br>
				<b-row>
					<b-col>
						<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="warning" class="mr-1">Submit</b-button>
						<b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="$router.push({ name:'email-users' })">Cancel</b-button>
					</b-col>
				</b-row>
			</b-card>
		</b-form>
	</b-row>

</div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio, BPagination,BFormCheckboxGroup, BBreadcrumb
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import flatPickr from 'vue-flatpickr-component';
export default {
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BMedia,
    BAvatar,
    BTable,
    BModal,
    VueCropper,
    BFormRadio,
    vSelect,
    BPagination,
    BFormCheckboxGroup,
    BBreadcrumb,
    flatPickr
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      
      error_message:null,
      showDismissibleAlert:false,
      //types: [],
      //issueTypes: [],
      frequencies:[],
      frequencies_monthly:[],
      report_types:[],

      // new vars
      report_list : [/* 'Schedule Work', 'Service Request',  */'Custom Form'/* , 'Defect Request' */],
      custom_form_list : [],
      task_frequency_list : [],
      task_group_list : [],
      task_crew_list : [],
      report_format_list : ['PDF'/* , 'Excel', 'Zip' */],
      page_breakdown_list : ['Single', 'Multiple'],
      report_type_list : ['To ask', 'To ask', 'To ask'],
      report_frequency_list : ['To ask', 'To ask', 'To ask'],
      email_send_date_list: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
            
      selectAll:false,

      form :{
        sites:[],
        name:'',
        email:'',
        types:[],
        frequency:[],

        report_breakdown : [{
            report_name        : '',
            report             : '',
            email_custom_forms : [],
            task_frequency     : null,
            task_group         : '',
            task_crew          : null,
            report_format      : '',
            page_breakdown     : '',
            report_type        : '',
            report_frequency   : '',
            email_send_date    : '',
            report_time        : null
        }],   
      },
      items:[],
      isSortDirDesc: true,
      perPageOptions: [10, 20, 50, 100],
      perPage : 10,
      sortBy : 'id',
      totalRecords : 0,
      currentPage : 1,
      searchQuery:'',
      from:null,
      to:null,

      company_users:[],
      company_user:'',

      tableColumns: [
            { key: 'checkbox', label: '', sortable: false , thStyle:  {width: '10%'}},
            { key: 'site', label: 'Project Site Name', sortable: false , thStyle:  {width: '30%'}},
            { key: 'name', label: 'Location Name', sortable: true , thStyle:  {width: '60%'}},
      ],
      
    }
  },
  methods : {

    checkforms(e){
        if (e.length > 0 && e[e.length-1]._id == 'all-forms') {
          
          this.form.email_custom_forms = [];
          
          this.custom_form_list.forEach(item => {
            if (item._id != 'all-forms') {
              this.form.email_custom_forms.push(item);
            }
          })
        }
    },
    filterTable(){
      this.$refs.refUserListTable.refresh();
    },
    formSubmit(e){
      e.preventDefault();

      return this.$store.dispatch(POST_API, {
           data:{
             items:this.form
           },
           api: '/api/update-email-user'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Record Updated Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    // this.$router.go(-1);
                    this.$router.push({ name:'email-users' })
                });
                
            }
        });
      
    },

    getSiteUsers(){
      return this.$store.dispatch(POST_API, {
           data:{
                sites:this.form.sites,
                role: ['administrator', 'operation_manager', 'site_manager', 'site_client', 'supervisor']
           },
           api: '/api/get-site-user'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                this.company_user = '';
                this.company_users = this.$store.getters.getResults.data;

                this.company_users.sort((a,b) => (a.full_name.toLowerCase() > b.full_name.toLowerCase()) ? 1 : ((b.full_name.toLowerCase() > a.full_name.toLowerCase()) ? -1 : 0))

                return this.company_users;
            }
        });
    },

    getTypes(){
      return this.$store.dispatch(POST_API, {
           data:{
                role:this.$store.getters.currentUser.role,
                om_sites:this.$store.getters.currentUser.om_sites,
           },
           api: '/api/email-user-dropdown'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data = this.$store.getters.getResults.data;
                
                //this.form.frequency = data.types;
                this.frequencies = data.frequency_types;
                this.frequencies.forEach(el => {
                    if(el.name == 'Monthly'){
                        this.frequencies_monthly.push(el);
                    }
                });
                this.report_types = data.report_types;
                // this.issueTypes = data.issue_types;
                this.items = data.sites;
                // this.company_users = data.company_users;

                // this.form.sites = this.items;
                // return this.types;
            }
        });
    },

    getEmailUser(){
      return this.$store.dispatch(POST_API, {
           data:{
             id: this.$route.params.id
           },
           api: '/api/email-user-detail'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var item = this.$store.getters.getResults.data;

                
                this.form.name = item.full_name;
                this.form.email = item.email;
              
                this.form.id  =  item._id;
                this.form.sites  =  item.email_sites;

                this.getForms().then(()=> {
                  this.getSiteUsers().then(()=> {
                    this.getSiteGroup().then(()=> {
                      this.siteCrew().then(()=> {
                        this.form.report_breakdown = item.report_breakdown;
                        // this.form.task_crew = item.task_crew;
                        // this.form.task_group= item.task_group;

                        //   this.task_frequency_list.forEach((el) => {
                        //     if(el._id == item.task_frequency){
                        //       this.form.task_frequency = el;
                        //     }
                        //   })

                        //   if(item.email_custom_forms){
                        //     this.custom_form_list.forEach(el => {
                        //       item.email_custom_forms.forEach(ch => {
                        //         if(ch == el._id) {
                        //           this.form.email_custom_forms.push(el)
                        //         }
                        //       })
                        //     })
                        //   }

                      })
                    })
                  })
                })

                // new vars
                // this.form.report_name     = item.report_name;
                // this.form.report			    = item.report;
                // // this.form.email_custom_forms = item.email_custom_forms;

                

                // this.form.report_format   = item.report_format;
                // this.form.page_breakdown  = item.page_breakdown;
                // this.form.email_send_date = item.email_send_date;
                // this.form.report_time     = item.report_time;                                
            }
        });
    },
    
    handleChange(e){
      
      // this.form.sites = [];
      console.log(e,'eee')
      if (e == null) {
        this.form.name = '';
        this.form.email = '';

      }else{
        this.form.name = e.full_name;
        this.form.email = e.email;

        if (e.role == 'admin' || e.role == 'administrator') {
          this.form.sites = this.items;
          
        }else if(e.role == 'operation_manager'){
          this.form.sites = e.om_sites;
        }else{
          if(e.sites){
            this.form.sites.push(e.site);
          }
        }
      }
      
    },

    getForms(){
      return this.$store.dispatch(POST_API, {
          data:{
            id: this.$store.getters.currentUser.organization,
            role: this.$store.getters.currentUser.role,
          },
          api: '/api/client-custom-forms'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              this.custom_form_list = this.$store.getters.getResults.data;
                
                if (this.custom_form_list.length > 0) {
                  
                  
                  this.custom_form_list.sort((a, b) => (a.form.title > b.form.title ? 1 : -1))

                  var obj = {
                    _id:'all-forms',
                    form : {
                      title:'All Forms'
                    }
                  }

                  this.custom_form_list.unshift(obj);

                }

                return this.custom_form_list;         
          }
      });
    },

    allFrequencies(){
      return this.$store.dispatch(POST_API, {
           data:{},
           api: '/api/all-periodic-frequencies'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.task_frequency_list = data;
            }
        });
    },

    getSiteGroup(){
      return this.$store.dispatch(POST_API, {
           data:{
             sites:this.form.sites,
           },
           api: '/api/multi-site-group'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                this.form.task_group = '';
                this.task_group_list = data;
            }
        });
    },

    siteCrew(){
        return this.$store.dispatch(POST_API, {
            data:{
                sites: this.form.sites,
                role: ['crew'],
            },
            api: '/api/get-site-user'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
            } else {
                this.showDismissibleAlert = false;
                this.form.task_crew = null;
                this.task_crew_list = this.$store.getters.getResults.data;

                this.task_crew_list.sort((a,b) => (a.full_name.toLowerCase() > b.full_name.toLowerCase()) ? 1 : ((b.full_name.toLowerCase() > a.full_name.toLowerCase()) ? -1 : 0))
            }
        });
    },

    removeRow(index) {
        this.form.report_breakdown.splice(index, 1);
        
        if(this.form.report_breakdown.length == 0){

          this.form.report_breakdown.push({
              report_name        : '',
              report             : '',
              email_custom_forms : [],
              task_frequency     : null,
              task_group         : '',
              task_crew          : null,
              report_format      : '',
              page_breakdown     : '',
              report_type        : '',
              report_frequency   : '',
              email_send_date    : '',
              report_time        : null
          })
        }
    },

    addRow(){
        this.form.report_breakdown.push({
            report_name        : '',
            report             : '',
            email_custom_forms : [],
            task_frequency     : null,
            task_group         : '',
            task_crew          : null,
            report_format      : '',
            page_breakdown     : '',
            report_type        : '',
            report_frequency   : '',
            email_send_date    : '',
            report_time        : null
        })
    },

    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'Email Listing',
      },{
        to:{name:'email-users'},
        text:'Email Users',
        
      },{
        to:null,
        text:'Edit Email User',
        active:true
      }];
      return item;
    }
  },
  mounted(){
    this.getTypes();
    this.getEmailUser();
    this.getForms();
    this.allFrequencies();
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>